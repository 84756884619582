import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"

const thankyou = () => {
  return (
    <>
      <Seo title="Thank you" />
      <Layout>
        <div className="section">
          <div className="container container__tight">
            <h1>Thank you for your message</h1>
            {/* <h3>..for getting in touch.</h3> */}
            <p>
              I will respond to your message as soon as I can.
            </p>
            <Button text="Return Home" to="/" as={Link} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default thankyou
